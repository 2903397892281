import React, { useEffect, useState } from "react";
import request from "graphql-request";
import { Row } from "reactstrap";
import ReactLoading from "react-loading";
import CardComponent from "../utilityComponents/CardComponent";

const ClientsComponent = () => {
  const [clients, setClients] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      const { clients } = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                  { 
                  clients{
                    id
                    fullname
                    password
                    mainPhoto{
                      url
                    }
                    number
                    photos{
                      url
                    }
                  }    
                } `,
      );
      setClients(clients);
    };
    fetchClients((clients) => setClients(clients)).then();
  }, []);

  return (
    <>
      {clients ? (
        <Row className="m-0 d-flex justify-content-start">
          {clients.map((category, i) => {
            console.log(category);
            const _category = clients.find(
              (category) => category.number === i + 1,
            );

            return (
              <CardComponent
                title={_category.fullname}
                mainPhoto={_category.mainPhoto.url}
                slug={_category.fullname}
                name="clients"
              />
            );
          })}
        </Row>
      ) : (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <ReactLoading
            type={"bars"}
            color={"white"}
            height={667 / 2}
            width={375 / 2}
          />
        </div>
      )}
    </>
  );
};

export default ClientsComponent;
