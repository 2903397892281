import React from "react";
import {isMobile} from "react-device-detect";
import {Router} from "@reach/router";
import Layout from "../components/layout";
import ClientsComponent from "../components/ClientsComponents/ClientsComponent";
import loadable from "@loadable/component";
import ClientComponent from "../components/ClientsComponents/ClientComponent";
const AnimatedCursor = loadable(() => import("../components/AnimatedCursor"))

const Clients = () => <Layout>
        {!isMobile && <AnimatedCursor/>}
        <Router>
            <ClientsComponent   path="/clients/"/>
            <ClientComponent path="/clients/:name"/>
        </Router>
    </Layout>

export default Clients;