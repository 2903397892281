import React, { useEffect, useState } from "react";
import { request } from "graphql-request";
import { Form, FormGroup, Label, Input } from "reactstrap";
import Gallery from "react-grid-gallery";

const ClientComponent = ({ name }) => {
  const [client, setClient] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    const fetchClients = async () => {
      const { clients } = await request(
        "https://api-eu-central-1.graphcms.com/v2/cklr9kcm991dw01z2fb6ngy2s/master",
        `
                  { 
                  clients{
                    id
                    fullname
                    password
                    mainPhoto{
                      url
                    }
                    number
                    photos{
                      url
                    }
                  }    
                } `,
      );
      return clients.find(({ fullname }) => fullname === name);
    };
    fetchClients().then((client) => setClient(client));
  }, []);

  return (
    <>
      {!isAuthorized ? (
        <Form
          id="contact-form"
          onSubmit={(e) => {
            e.preventDefault();
            if (password === client.password) {
              setIsAuthorized(true);
            }
          }}
        >
          <FormGroup>
            <Label>Wpisz hasło</Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>
          <button type="submit">Zaloguj</button>
        </Form>
      ) : (
        <div className="d-flex flex-column justify-content-center">
          {client && client.photos && (
            <Gallery
              className="gallery-react"
              images={client.photos
                .map((item) => ({
                  src: item.url,
                  thumbnail: item.url,
                }))
                .reverse()}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ClientComponent;
